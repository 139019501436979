.button {
    display: inline-block;
    padding: 15px 25px;
    font-size: 24px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #fff;
    background-color: #4CAF50;
    border: none;
    border-radius: 15px;
    box-shadow: 0 9px #999;
    margin:20px;
  }
  
  .button:hover {background-color: #3e8e41}
  
  .button:active {
    background-color: #3e8e41;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }
  .whole{
    padding-top: 25px;
    margin: auto;
    padding-left: 50px;
    padding-right: 50px;
  }
  .butcont{
    text-align: center;
  }
  .mw{
    max-width: 30px;
  }
  .head{
    text-align: center;
    font-weight: 300;
    font-family: 'Raleway', sans-serif;
    font-size: xx-large
  }
  .smol{
    font-size: large;
    max-width: 75%;
    text-overflow: unset;
  }
  .headerimg{
    max-width: 20%;
  }
  .reeed{
    color: red  ;
  }.imagesss{
    max-width: 75%;
    margin: auto;
    padding-bottom: 10px;
  }