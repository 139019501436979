.text{
  color:whitesmoke
}
.headertext{
  color:white
}

.centerEverything{
  margin: auto;
  text-align: center;
  padding-top: 10%;
  padding-bottom: 10%;
  
}
.AThing{
  padding-left: 10px; 
  padding-right: 10px;
  color: #384a57;
}
.footer{
  margin: auto;
  text-align: center;
}
